import styled from "@emotion/styled";
import {Card} from "../Card/Card";
import {calcRem, fontRemScale, breakpoint} from "@the-game/components/utils";
export const StyledMarginInputCard = styled(Card)(({theme}) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
  padding: calcRem(theme.spacing.xs, theme.spacing.md),
  borderRadius: 0,
  borderTop: `1px solid ${theme.colors.palette.neutral50}`,
  boxShadow: "none"
}));
export const StyledHeaderContainer = styled("span")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  [breakpoint("sm")]: {
    marginRight: calcRem(70),
    justifyContent: "flex-end"
  }
}));
export const StyledMarginToolTipContainer = styled("span")(() => ({
  marginLeft: calcRem(10),
  maxHeight: calcRem(22)
}));
export const StyledMarginSpinButtonContainer = styled("span")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  [breakpoint("sm")]: {
    justifyContent: "flex-start",
    marginLeft: calcRem(20)
  }
}));
export const StyledHeadingH1 = styled("h1")(() => ({
  fontWeight: "bold",
  fontSize: fontRemScale(1)
}));

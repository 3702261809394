import styled from "@emotion/styled";
import {calcRem} from "@the-game/components/utils";
import {Avatar} from "../Avatar/Avatar";
export const StyledGrid = styled("div")(({theme, columns}) => ({
  gridTemplateColumns: `auto repeat(${columns}, 40px)`,
  gridGap: theme.spacing.xs,
  display: "grid",
  alignItems: "center"
}));
export const StyledAvatar = styled(Avatar)(({theme}) => ({
  border: `2px solid ${theme.colors.white}`
}));
export const StyledWrapper = styled("div")({
  maxWidth: "calc(100%)",
  overflow: "auto"
});
export const StyledPlaceholder = styled("div")(({theme}) => ({
  width: calcRem(40),
  height: calcRem(40),
  border: `2px dashed ${theme.colors.palette.neutral50}`,
  borderRadius: "50%"
}));
export const StyledHeader = styled("div")({
  textAlign: "center",
  fontSize: calcRem(14),
  lineHeight: calcRem(16),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  span: {
    height: calcRem(16),
    display: "block"
  },
  div: {
    marginBottom: 2
  }
});
export const StyledUsername = styled("div")(({theme, isExpert}) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gridGap: isExpert ? theme.spacing.md : theme.spacing.sm,
  alignItems: "center"
}));
export const StyledFullName = styled("p")(({theme}) => ({
  fontWeight: theme.weights.bold
}));
export const StyledUserTitle = styled("p")(({theme}) => ({
  color: theme.colors.palette.neutral70
}));
export const StyledLine = styled("div")(({variant, theme}) => ({
  width: "100%",
  height: 1,
  background: variant === "strong" ? theme.colors.palette.neutral50 : variant === "blue" ? theme.colors.palette.bayBlue : theme.colors.palette.neutral30,
  gridColumn: "1 / -1"
}));
export const StyledTeam = styled("span")(({winner, theme}) => ({
  fontWeight: winner ? theme.weights.bold : theme.weights.regular
}));
export const StyledRowHeading = styled("span")(({theme}) => ({
  fontWeight: theme.weights.bold,
  alignSelf: "flex-end"
}));
export const StyledResult = styled("div")({
  position: "relative",
  "> :nth-child(2)": {
    position: "absolute",
    width: 20,
    height: 20,
    right: 0,
    bottom: 0
  }
});
export const EmptyRowContent = styled("div")({
  height: calcRem(40),
  gridColumn: "1 / -1"
});
export var Winner;
(function(Winner2) {
  Winner2["HOME"] = "home";
  Winner2["AWAY"] = "away";
  Winner2["NONE"] = "none";
})(Winner || (Winner = {}));

var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {calcRem} from "@the-game/components/utils";
import {
  insetFocusStyle,
  visuallyHiddenStyle
} from "@the-game/components/utils";
import Check from "../Icons/Check";
import styled from "@emotion/styled";
export const StyledCheckboxLabel = styled("label")(({alignItems, fluid}) => ({
  display: "inline-flex",
  alignItems,
  width: fluid ? "100%" : "auto",
  cursor: "pointer"
}));
export const StyledCheckboxChildren = styled("div")(({theme}) => ({
  margin: 0,
  marginLeft: calcRem(theme.spacing.xs),
  fontFamily: theme.fontFamily
}));
export const StyledCheckbox = styled("div")(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: calcRem(theme.spacing.lg),
  minWidth: calcRem(theme.spacing.lg),
  height: calcRem(theme.spacing.lg),
  padding: 2,
  backgroundColor: theme.colors.white,
  borderWidth: 2,
  borderColor: theme.colors.palette.neutral90,
  borderStyle: "solid"
}));
export const StyledCheck = styled(Check)(({theme}) => ({
  flexGrow: 1,
  fill: theme.colors.primary,
  opacity: 0,
  transform: "scale(0.6)",
  transformOrigin: "center",
  transitionProperty: "opacity, transform",
  transition: "0.1s ease-out"
}));
export const StyledCheckboxInput = styled("input")(({theme}) => __assign(__assign(__assign({}, visuallyHiddenStyle), insetFocusStyle(theme, "&:focus-visible + div")), {
  [`&:focus + div`]: {
    borderColor: theme.colors.primary
  },
  [`&:checked + div`]: {
    [`& > svg`]: {
      opacity: 1,
      transform: "scale(1)"
    }
  },
  [`&:disabled + div`]: {
    backgroundColor: theme.colors.palette.neutral30,
    borderColor: theme.colors.palette.neutral50,
    [`& > svg`]: {
      fill: theme.colors.palette.neutral50
    }
  }
}));

import styled from "@emotion/styled";
import {breakpoint, calcRem} from "@the-game/components/utils";
export const StyledSportSwitcherContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center"
}));
export const StyledTabs = styled("div")(() => ({
  "> :nth-child(1)": {
    borderRadius: "4px 0px 0px 4px",
    borderLeftWidth: 2
  },
  "> :nth-last-child(1)": {
    borderRadius: "0px 4px 4px 0px"
  }
}));
export const StyledTab = styled("button")(({theme, isActive}) => ({
  cursor: "pointer",
  border: isActive ? "none" : `2px solid ${theme.colors.palette.bayBlue}`,
  borderLeftWidth: 0,
  backgroundColor: isActive ? theme.colors.palette.bayBlue : "transparent",
  color: isActive ? "white" : theme.colors.palette.bayBlue,
  padding: isActive ? calcRem(theme.spacing.xs) : calcRem(6),
  fontSize: calcRem(14),
  width: calcRem(105),
  [breakpoint("xs")]: {
    width: calcRem(115)
  },
  [breakpoint("sm")]: {
    width: calcRem(172)
  }
}));

import React from 'react'
import { InputVariants } from '@the-game/components/utils'
import { StyledInput, StyledInputContainer } from './Input.styled'

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    variant?: InputVariants
    fluid?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        { type = 'text', variant = 'normal', required, fluid = true, ...props },
        ref,
    ) => (
        <StyledInputContainer>
            <StyledInput
                ref={ref}
                type={type}
                variant={variant}
                required={required}
                fluid={fluid}
                aria-required={required ? true : undefined}
                {...props}
            />
        </StyledInputContainer>
    ),
)

import React from 'react'
import styled from '@emotion/styled'
import { MenuSection } from '../types'
import { ChevronDown } from '../../Icons/ChevronDown'
import { Box } from '../../Box'
import { Link } from '../../Link'
import {
    breakpoint,
    elevations,
    calcRem,
    focusStyle,
    insetFocusStyle,
} from '@the-game/components/utils'
import { StyledPill } from '@the-game/components/Pill/Pill.styled'
import { StyledInactiveMenuItem } from '../GlobalHeader'
import { showOffseasonMenuLabel } from '@the-game/components/utils/showOffsersonMenuLabel'

const { Elevation1 } = elevations

const StyledMenuGroupTitle = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1.5rem 1fr 1.5rem',
    zIndex: 2,
    position: 'relative',
    cursor: 'pointer',
    padding: theme.spacing.md,
    borderRadius: 4,
    border: 'none',

    flexDirection: 'row',
    fontWeight: theme.weights.bold,
    gridGap: theme.spacing.xs,

    backgroundColor: theme.colors.white,
    svg: {
        height: '1.5rem',
    },
    '&:hover': {
        backgroundColor: theme.colors.palette.neutral30,
        outline: 'none',
    },
    ...focusStyle(theme),
}))
const StyledMenuSection = styled('div')(({ theme }) => ({
    position: 'relative',
    marginRight: theme.spacing.md,
}))

const StyledSubMenu = styled(Box)<{ show: boolean }>(({ theme, show }) => ({
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.colors.white,
    transition: 'ease-in-out opacity 0.1s',
    opacity: show ? 1 : 0,
    height: show ? 'auto' : 0,
    overflow: 'hidden',
    padding: 0,
    width: calcRem(170),
    top: 50,
    borderRadius: 8,
    ul: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        li: {
            width: '100%',
            fontWeight: theme.weights.regular,
        },

        'li:first-child': {
            marginTop: theme.spacing.lg,
        },
        'li:last-child': {
            marginBottom: theme.spacing.lg,
        },
        a: {
            textDecoration: 'none',
            color: theme.colors.palette.neutral90,
            padding: theme.spacing.sm,
            display: 'block',
            '&:hover': {
                backgroundColor: theme.colors.palette.neutral30,
                textDecoration: 'underline',
            },
            ...insetFocusStyle(theme),
        },
    },
    ...Elevation1,
}))

const StyledMenuLink = styled(Link)(({ theme }) => ({
    color: theme.colors.palette.neutral90,
    height: 'calc(100%)',
    alignItems: 'center',
    alignSelf: 'flex-start',
    textDecoration: 'none',
    padding: theme.spacing.md,
    paddingTop: 3,
    paddingBottom: 3,
    marginRight: theme.spacing.md,
    boxSizing: 'border-box',
    display: 'grid',
    fontWeight: theme.weights.bold,
    gridGap: theme.spacing.sm,
    gridTemplateColumns: 'auto 1fr auto',
    '&:hover': {
        borderBottom: `${theme.colors.primary} solid 3px`,
        paddingBottom: 0,
    },
}))

export const StyledNav = styled('nav')(({ theme }) => ({
    display: 'none',
    [breakpoint('lg')]: {
        display: 'flex',
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',

        ul: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            padding: 0,
            margin: 0,
            listStyle: 'none',
            li: {
                fontWeight: theme.weights.bold,
                height: '100%',
                a: {
                    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,
                },
            },
        },
    },
}))

const OffSeasonNotice = styled('div')(({ theme }) => ({
    margin: `0 ${calcRem(theme.spacing.xs)}`,
    borderTop: `1px solid ${theme.colors.palette.neutral50}`,
    padding: `${calcRem(theme.spacing.md)} 0`,
    textAlign: 'center',
    color: theme.colors.palette.neutral70,
    fontSize: calcRem(12),
}))

const DesktopNavigationStyledPill = styled(StyledPill)(({ theme }) => ({
    borderColor: theme.colors.palette.orange500,
    color: theme.colors.palette.orange500,
    display: 'inline-block',
    marginBottom: theme.spacing.xs,
}))

export function MenuSectionComponent({ item }: { item: MenuSection }) {
    const [show, setShow] = React.useState(false)
    const { icon, title, children } = item

    return (
        <StyledMenuSection
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onFocus={() => setShow(true)}
            onBlur={() => setShow(false)}
        >
            <StyledMenuGroupTitle tabIndex={0} onClick={() => setShow(true)}>
                {icon({
                    size: 'sm',
                    themeColor: 'bayBlue',
                })}{' '}
                {title} <ChevronDown aria-hidden title="" size="xs" />
            </StyledMenuGroupTitle>
            <StyledSubMenu show={show}>
                <ul>
                    {children.map(({ link, title, isInactive }, c) => (
                        <li key={c}>
                            {isInactive ? (
                                <StyledInactiveMenuItem>
                                    {title}
                                </StyledInactiveMenuItem>
                            ) : (
                                <Link
                                    onClick={() => setShow(false)}
                                    to={link}
                                    onFocus={() => setShow(true)}
                                    onBlur={() => setShow(false)}
                                >
                                    {title}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>

                {showOffseasonMenuLabel(title) && (
                    <OffSeasonNotice>
                        <DesktopNavigationStyledPill>
                            Off season
                        </DesktopNavigationStyledPill>
                        <p>{title} is currently inactive</p>
                    </OffSeasonNotice>
                )}
            </StyledSubMenu>
        </StyledMenuSection>
    )
}

export function DesktopNavigation({ items }: { items: MenuSection[] }) {
    return (
        <StyledNav>
            {items.map((item, index) =>
                item.link ? (
                    <StyledMenuLink to={item.link} key={index}>
                        {item.icon({
                            size: 'sm',
                            themeColor: 'bayBlue',
                            'aria-hidden': true,
                            title: '',
                        })}
                        {item.title}
                    </StyledMenuLink>
                ) : (
                    <MenuSectionComponent item={item} key={index} />
                ),
            )}
        </StyledNav>
    )
}

import {calcRem, breakpoint, fontRemScale} from "@the-game/components/utils";
import styled from "@emotion/styled";
export const BadgeList = styled("div")(({size, theme}) => ({
  display: "grid",
  gridColumnGap: 2,
  alignSelf: "flex-end",
  span: {
    width: "100%",
    fontSize: size === "sm" ? fontRemScale(0.75) : fontRemScale(1),
    fontWeight: size === "sm" ? theme.weights.medium : theme.weights.bold
  },
  gridTemplateColumns: `repeat(5, ${size === "sm" ? calcRem(14) : calcRem(24)})`,
  [breakpoint("sm")]: {
    gridTemplateColumns: `repeat(5, ${size === "sm" ? calcRem(20) : calcRem(24)})`,
    span: {
      fontSize: size === "sm" ? fontRemScale(0.75) : fontRemScale(1)
    }
  }
}));
export const StyledSpan = styled("span")({
  gridColumn: "span 5",
  textAlign: "right",
  fontSize: fontRemScale(0.875)
});

import { GameTheme } from '../theme'

export function focusStyle(theme: GameTheme, selector = '&:focus-visible') {
    return {
        ':focus': {
            outline: 'none',
        },
        [selector]: {
            boxShadow: `0 0 0 2px ${theme.colors.primary}`,
            outline: 'none',
        },
    }
}

export default focusStyle

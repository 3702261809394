import React from 'react'
import { Card } from '../Card/Card'
import { CompetitionRank } from '../CompetitionRank/CompetitionRank'
import { Help } from '../Icons/Help'
import { Trophy } from '../Icons/Trophy'
import { Football } from '../Icons/Football'
import { VisuallyHidden } from '../VisuallyHidden'

import { TheGameLogo } from '../logos'
import {
    StyledCompetitionLink,
    StyledCompetitionBadge,
    StyledCardBody,
    StyledCompetitionCard,
    StyledCompetitionHeader,
    StyledInfo,
} from './CompetitionCard.styled'
import { SportName } from '@the-game/api-interfaces'
import { CricketBall, WaflFootball } from '../Icons'

function CompetitionBadge() {
    return (
        <StyledCompetitionBadge>
            <span aria-hidden>1st</span>
            <VisuallyHidden>You are leading this competition</VisuallyHidden>
            <Trophy size="md" themeColor="gold900" />
        </StyledCompetitionBadge>
    )
}

export interface CompetitionCardProps {
    sport: SportName
    rank?: number
    previousRank?: number
    memberTotal?: number
    kind?: 'default' | 'overall'
    title: string
    /** ID not needed if kind is overall, it will just be ignored */
    id: string | undefined
    isLoading?: boolean
}

export function CompetitionCard({
    title,
    isLoading = false,
    rank,
    previousRank,
    memberTotal,
    kind = 'default',
    sport,
    id,
}: CompetitionCardProps): JSX.Element {
    const showBadge = rank === 1
    const cardUrl =
        kind === 'overall'
            ? `/${sport}/tipping/leaderboard`
            : `/${sport}/tipping/comp/${id}/leaderboard`

    const buttonGraphic = () => {
        if (kind !== 'default') {
            return (
                <TheGameLogo
                    size="xl"
                    themeColor={sport === 'Cricket' ? 'neutral90' : 'white'}
                />
            )
        }

        const iconConfig = {
            AFL: <Football size="xl" rotate={45} />,
            WAFL: <WaflFootball size="xl" />,
            Cricket: <CricketBall size="xl" />,
        }

        return iconConfig[sport]
    }

    return (
        <Card padding="unset" hideOverflow>
            <StyledCompetitionCard>
                <StyledCompetitionLink to={cardUrl}>
                    <StyledCompetitionHeader>
                        <h4>{title}</h4>
                        {buttonGraphic()}
                    </StyledCompetitionHeader>
                    <StyledCardBody showBadge={showBadge}>
                        {showBadge && <CompetitionBadge />}
                        <CompetitionRank
                            isLoading={isLoading}
                            showBadge={showBadge}
                            rank={rank}
                            memberTotal={memberTotal}
                            previousRank={previousRank}
                            sport={sport}
                        />
                    </StyledCardBody>
                </StyledCompetitionLink>

                {kind === 'default' ? (
                    <StyledInfo to={`/${sport}/tipping/comp/${id}/tips`}>
                        <Help
                            aria-hidden
                            title=""
                            themeColor="bayBlue"
                            size="sm"
                        />
                        Who tipped what?
                    </StyledInfo>
                ) : (
                    <StyledInfo to={`/prizes?sport=${sport}`}>
                        <Trophy
                            aria-hidden
                            title=""
                            themeColor="bayBlue"
                            size="sm"
                        />
                        Prizing information
                    </StyledInfo>
                )}
            </StyledCompetitionCard>
        </Card>
    )
}

export default CompetitionCard

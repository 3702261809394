import {calcRem} from "@the-game/components/utils";
import styled from "@emotion/styled";
import {Stack} from "../Stack";
import {Card} from "../Card/Card";
import {breakpoint} from "@the-game/components/utils";
export const StyledCard = styled(Card)({
  minHeight: calcRem(71),
  width: "100%",
  borderRadius: "8px",
  display: "grid",
  gridTemplateColumns: "2fr 3fr",
  gridTemplateAreas: [
    `
    "score results"
    "margincard margincard"
    `
  ],
  paddingTop: calcRem(20),
  paddingBottom: calcRem(20),
  [breakpoint("sm")]: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 3fr",
    gridTemplateAreas: ['"score results margincard"']
  },
  [breakpoint("lg")]: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 3fr",
    gridTemplateAreas: ['"score results margincard"']
  }
});
export const StyledResultsStack = styled(Stack)(({theme}) => ({
  gridArea: "results",
  flexWrap: "wrap",
  "> div": {
    width: calcRem(theme.metrics.icons.sm),
    height: calcRem(theme.metrics.icons.sm),
    padding: calcRem(2),
    marginRight: calcRem(theme.spacing.xs),
    marginBottom: calcRem(theme.spacing.xs),
    display: "flex"
  },
  [breakpoint("sm")]: {
    padding: calcRem(theme.spacing.sm)
  },
  [breakpoint("lg")]: {
    "> div": {
      width: calcRem(theme.metrics.icons.md),
      height: calcRem(theme.metrics.icons.md),
      padding: calcRem(2),
      display: "flex"
    }
  }
}));
export const StyledScoreStack = styled(Stack)(({theme}) => ({
  gridArea: "score",
  justifyContent: "center",
  marginBottom: theme.spacing.sm,
  [breakpoint("sm")]: {
    marginLeft: theme.spacing.lg
  }
}));
export const StyledCorrectTips = styled("h6")(({theme}) => ({
  fontSize: theme.spacing.md,
  marginBottom: 0,
  lineHeight: calcRem(theme.spacing.lg),
  fontWeight: 400,
  marginTop: 0
}));
export const StyledScore = styled("h6")(({theme}) => ({
  fontSize: calcRem(23),
  marginBottom: 0,
  lineHeight: calcRem(theme.spacing.xl),
  fontWeight: 600,
  marginTop: 0,
  [breakpoint("sm")]: {
    fontSize: calcRem(25)
  }
}));
export const StyledTipsSubmittedStack = styled("div")(({theme}) => ({
  justifyContent: "center",
  display: "grid",
  gridGap: theme.spacing.md,
  gridAutoFlow: "column",
  h6: {
    marginBottom: 0
  }
}));

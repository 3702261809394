import styled from "@emotion/styled";
import {calcRem} from "../utils/calcRem";
import {Button} from "../Button";
import {breakpoint} from "@the-game/components/utils";
import {MOBILE_STICKY_HEIGHT, TABLET_STICKY_HEIGHT} from "../utils/constants";
const stickyContainerHeight = calcRem(96);
const stickyElevation = `0 1px 0 0 rgba(0,0,0,0.08), 0 2px 32px 0 rgba(0,0,0,0.41), 0 29px 50px -30px rgba(0,0,0,0.34), 0 20px 50px -12px rgba(0,0,0,0.17), 0 10px 24px 0 rgba(0,0,0,0.05)`;
const buttonWidth = 330;
export const StyledSubmitButton = styled(Button)(({pristine, isSaved}) => {
  const transformDelay = !pristine && isSaved ? "3s" : "0s";
  const translation = pristine || isSaved ? 200 : 0;
  const opacity = pristine || isSaved ? 0 : 100;
  return {
    transition: `ease-in-out transform 0.3s ${transformDelay}, ease-in-out background-color 0.1s, ease-in-out opacity ${transformDelay}`,
    transform: `translateY(${translation}px)`,
    opacity: `${opacity}`,
    boxShadow: stickyElevation,
    pointerEvents: "auto",
    width: "100%",
    [breakpoint("sm")]: {
      width: calcRem(buttonWidth)
    },
    "&:disabled": {
      boxShadow: stickyElevation
    }
  };
}, ({isSaved, theme}) => isSaved && {
  "&:disabled": {
    color: theme.colors.black,
    backgroundColor: theme.colors.palette.neutral30,
    svg: {
      width: "1.5rem",
      height: "1.5rem",
      marginTop: 0,
      marginBottom: 0
    },
    path: {
      color: theme.colors.palette.green500,
      fill: theme.colors.palette.green500
    }
  }
});
export const StyledStickyStack = styled("div")(({theme}) => ({
  position: "fixed",
  paddingBottom: theme.spacing.md,
  zIndex: theme.zIndex.stickyFooter,
  display: "flex",
  alignItmes: "center",
  justifyContent: "center",
  margin: 0,
  alignItems: "center",
  height: "6rem",
  maxHeight: "6rem",
  pointerEvents: "none",
  width: `calc(100% - ${calcRem(theme.spacing.md * 2)})`,
  bottom: MOBILE_STICKY_HEIGHT,
  [breakpoint("sm")]: {
    bottom: TABLET_STICKY_HEIGHT,
    width: "auto"
  },
  [breakpoint("md")]: {
    bottom: 0
  }
}));
export const StyledContainer = styled("div")(({theme}) => ({
  display: "flex",
  alignItmes: "center",
  justifyContent: "center",
  margin: 0,
  alignItems: "center",
  height: stickyContainerHeight,
  maxHeight: stickyContainerHeight,
  pointerEvents: "none",
  width: `calc(100% - ${calcRem(theme.spacing.md * 2)})`,
  [breakpoint("sm")]: {
    width: "auto"
  }
}));
export const StyledPlaceholder = styled("div")({
  height: stickyContainerHeight
});
export const Relative = styled("div")({
  position: "relative",
  marginLeft: 0,
  [breakpoint("sm")]: {
    marginLeft: `calc(50% - ${calcRem(buttonWidth / 2)})`
  }
});

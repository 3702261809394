var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {calcRem} from "@the-game/components/utils";
import {Box} from "../Box";
import styled from "@emotion/styled";
import {modifyAlpha} from "../utils/modifyAlpha";
export const StyledBadge = styled(Box)(({theme, kind, variant}) => __assign({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: calcRem(theme.spacing.sm),
  fontWeight: 700,
  lineHeight: 1
}, getKindStyles(theme, kind, variant)));
function getKindStyles(theme, kind, variant) {
  switch (kind) {
    case "win":
      return variant === "ghost" ? getGhostStyle(theme.colors.palette.green500) : getSolidStyle(theme.colors.palette.green500);
    case "loss":
      return variant === "ghost" ? getGhostStyle(theme.colors.loss) : getSolidStyle(theme.colors.loss);
    default:
      return variant === "ghost" ? getGhostStyle(theme.colors.palette.neutral90) : getSolidStyle(theme.colors.palette.neutral90);
  }
}
function getSolidStyle(color) {
  return {
    backgroundColor: modifyAlpha(color, 0.06),
    color,
    transition: "all 0.1s linear"
  };
}
function getGhostStyle(primary) {
  return {
    borderColor: "currentcolor",
    borderStyle: "solid",
    borderWidth: 2,
    color: primary
  };
}

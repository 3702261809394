import {calcRem} from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledTableWrapper = styled("div")(({theme}) => ({
  display: "block",
  width: "100%",
  marginBottom: calcRem(theme.spacing.md),
  overflowX: "auto"
}));
export const StyledTable = styled("table")(({theme}) => ({
  width: "100%",
  borderCollapse: "collapse",
  "th, td": {
    padding: calcRem(theme.spacing.md),
    backgroundColor: theme.colors.white
  },
  th: {
    textAlign: "left",
    fontWeight: theme.weights.bolder
  },
  "tr:hover": {
    td: {
      backgroundColor: theme.colors.palette.neutral30
    }
  }
}), ({theme, dividers}) => dividers && {
  tr: {
    borderBottom: `1px solid ${theme.colors.palette.neutral30}`,
    "&:last-child": {
      borderColor: theme.colors.palette.neutral50
    }
  },
  "thead > tr:last-child": {
    borderBottomColor: theme.colors.primary
  },
  "thead > tr": {
    borderTop: `1px solid ${theme.colors.palette.neutral30}`
  }
}, ({theme, highlightRow}) => highlightRow !== void 0 && {
  [`tbody tr:nth-child(${highlightRow})`]: {
    borderColor: theme.colors.primary
  }
});

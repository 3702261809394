var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {calcRem} from "@the-game/components/utils";
import {breakpoint, getElevationBySize} from "@the-game/components/utils";
import styled from "@emotion/styled";
export const MultiBetContainerStyled = styled("div")(({theme}) => ({
  backgroundColor: theme.colors.palette.tab,
  borderRadius: theme.spacing.xs,
  color: theme.colors.white,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing.md,
  [breakpoint("sm")]: {
    alignItems: "center"
  }
}));
export const StyledBettingInformation = styled("div")(({theme}) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  color: theme.colors.white,
  marginRight: theme.spacing.xs,
  [breakpoint("sm")]: {
    alignItems: "center"
  },
  "& > *": {
    marginBottom: theme.spacing.xs
  },
  "& > :last-child": {
    marginBottom: 0
  },
  "& > :first-child": {
    width: calcRem(116),
    height: calcRem(24),
    [breakpoint("sm")]: {
      width: calcRem(116),
      height: calcRem(60)
    }
  },
  "& > p": {
    fontSize: calcRem(14),
    lineHeight: 16 / 14
  },
  "& small": {
    fontSize: calcRem(12),
    lineHeight: 16 / 12
  },
  [breakpoint("sm")]: {
    alignItems: "center",
    flexDirection: "row"
  }
}));
export const StyledWinAndBet = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  alignContent: "center",
  marginLeft: "auto",
  maxWidth: calcRem(124),
  [breakpoint("sm")]: {
    maxWidth: calcRem(124 * 2 + theme.spacing.xs),
    flexWrap: "nowrap",
    alignItems: "flex-end"
  }
}));
export const StyledWinReturn = styled("div")(({theme}) => ({
  width: calcRem(124),
  height: calcRem(40),
  backgroundColor: theme.colors.white,
  borderRadius: 4,
  color: theme.colors.palette.neutral90,
  textAlign: "center",
  paddingTop: theme.spacing.xs,
  paddingBottom: theme.spacing.xs
}));
export const StyledBetNow = styled("a")(({theme}) => __assign(__assign({
  width: calcRem(124),
  height: calcRem(40),
  backgroundColor: "#f5af09",
  borderRadius: 4,
  fontWeight: theme.weights.bold,
  color: theme.colors.palette.neutral90,
  textAlign: "center",
  paddingTop: theme.spacing.xs,
  paddingBottom: theme.spacing.xs,
  textDecoration: "none",
  marginTop: theme.spacing.xs,
  [breakpoint("sm")]: {
    marginTop: 0,
    marginLeft: theme.spacing.xs
  }
}, getElevationBySize(1)), {
  "&:hover": __assign(__assign({}, getElevationBySize(2)), {
    textDecoration: "underline"
  })
}));

var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import Button from "../Button";
import {calcRem} from "../utils/calcRem";
import styled from "@emotion/styled";
import Skeleton from "../Skeleton";
import Stack from "../Stack";
import {breakpoint} from "../utils";
export function PaginationButton(props) {
  return /* @__PURE__ */ React.createElement(Button, __assign(__assign({}, props), {
    size: "sm"
  }));
}
export const StyledExampleItem = styled("div")(({theme}) => ({
  width: calcRem(100),
  height: calcRem(50),
  border: "1px solid black",
  backgroundColor: theme.colors.palette.neutral30,
  marginBottom: "1rem",
  alignItems: "center",
  justifyContent: "center",
  display: "flex"
}));
export const StyledControlContainer = styled(Stack)({
  gridColumn: "1 / -1",
  [breakpoint("sm")]: {
    justifyContent: "center"
  }
});
export const PaginatorControlsSkeleton = styled(Skeleton)({
  height: calcRem(40),
  width: "100%",
  minWidth: "3rem"
});
export const StyledPaginationButton = styled(PaginationButton)(() => ({
  borderRadius: 0,
  width: calcRem(44)
}));
export const StyledEllipsis = styled("span")(() => ({
  minWidth: calcRem(44),
  fontSize: "1.125rem",
  lineHeight: "1.5rem",
  alignItems: "center",
  justifyContent: "center",
  display: "inline-flex"
}));
export const StyledControlPlaceholder = styled("div")({
  height: calcRem(40)
});

var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import styled from "@emotion/styled";
import {focusStyle} from "@the-game/components/utils";
export const StyledLinkButton = styled("button")(({theme, variant = "default"}) => __assign(__assign({
  display: "inline",
  padding: 0,
  appearance: "none",
  background: "transparent",
  border: 0,
  color: variant === "default" ? theme.colors.primary : variant === "white" ? theme.colors.white : theme.colors.loss,
  cursor: "pointer",
  textTransform: "none",
  textDecoration: "underline",
  transition: "color linear 0.3s",
  fill: theme.colors.primary
}, focusStyle(theme)), {
  "&:hover": {
    color: theme.colors.palette.neutral90,
    fill: theme.colors.palette.neutral90
  }
}));

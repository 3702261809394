var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {Card} from "../Card/Card";
import {calcRem} from "@the-game/components/utils";
import {Box} from "../Box";
import {Stack} from "../Stack";
import styled from "@emotion/styled";
import {breakpointMax} from "@the-game/components/utils";
import isPropValid from "@emotion/is-prop-valid";
export const StyledCard = styled(Card)(({theme, disabled}) => ({
  label: {
    backgroundColor: disabled ? theme.colors.palette.neutral30 : theme.colors.white
  },
  transition: theme.transitions.swift,
  position: "relative",
  listStyle: "none"
}));
export const StyledLocation = styled(Stack)(({theme}) => ({
  fontSize: calcRem(16),
  color: theme.colors.palette.neutral70,
  lineHeight: calcRem(24),
  [breakpointMax("sm")]: {
    gridColumn: "span 2",
    alignItems: "center"
  }
}));
export const StyledBox = styled(Box)({
  listStyle: "none",
  boxShadow: "none"
});
export const MatchStatusWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [breakpointMax("sm")]: {
    justifyContent: "flex-end"
  }
});
export const MatchStatusBadge = styled("span")(({theme}) => ({
  borderRadius: 4,
  backgroundColor: theme.colors.palette.neutral30,
  padding: `0px ${theme.spacing.xs}px`
}));
export const StyledGrid = styled("div")(({theme}) => ({
  display: "grid",
  gridTemplateColumns: "3fr 2fr 3fr",
  padding: `${calcRem(6)} ${calcRem(16)}`,
  borderBottom: `1px solid ${theme.colors.palette.neutral50}`,
  alignItems: "center",
  height: calcRem(60),
  minHeight: calcRem(60),
  gridGap: "0px",
  [breakpointMax("sm")]: {
    gridTemplateColumns: "1fr 1fr",
    height: calcRem(100),
    padding: calcRem(16)
  },
  [breakpointMax("xs")]: {
    height: calcRem(120)
  }
}));
export const StyledStatusIconWrapper = styled("div", {
  shouldForwardProp: isPropValid
})(({theme, isCompleted = false}) => __assign(__assign({
  position: "absolute",
  cursor: "not-allowed",
  backgroundColor: isCompleted ? void 0 : theme.colors.white,
  top: calcRem(99),
  left: "calc(50% - 19px)",
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  border: "none",
  zIndex: theme.zIndex.tippingBadge
}, theme.elevations.Elevation1), {
  svg: {
    height: 36
  },
  [breakpointMax("sm")]: {
    top: calcRem(179)
  },
  [breakpointMax("xs")]: {
    top: calcRem(189)
  }
}));
export const StyledBadge = styled("div")(({theme, color}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  height: calcRem(28),
  color,
  svg: {
    marginRight: theme.spacing.xxs,
    width: 22,
    height: 22
  }
}));

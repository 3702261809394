var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {Button} from "../Button";
import {calcRem} from "@the-game/components/utils";
import {StyledInput} from "../Input/Input.styled";
import styled from "@emotion/styled";
import {focusStyle} from "@the-game/components/utils";
export const StyledSpinButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start"
});
export const StyledSpinInput = styled(StyledInput)(({theme}) => ({
  padding: 0,
  margin: 0,
  appearance: "textfield",
  MozAppearance: "textfield",
  WebkitAppearance: "textfield",
  textAlign: "center",
  width: calcRem(60),
  borderWidth: 2,
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "&:disabled": {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.palette.neutral50
  }
}));
export const StyledSpinButton = styled(Button)(({theme}) => __assign(__assign({
  padding: calcRem(theme.spacing.xxs, theme.spacing.md),
  margin: 0,
  borderWidth: 2,
  height: calcRem(40)
}, focusStyle(theme)), {
  "&:first-child": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0
  },
  "&:last-child": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0
  }
}));

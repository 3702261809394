var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import styled from "@emotion/styled";
import {gradients} from "@the-game/components";
import {
  calcRem,
  fontRemScale,
  breakpoint,
  insetFocusStyle
} from "@the-game/components/utils";
import {Link} from "react-router-dom";
export const StyledCompetitionCard = styled("div")(({theme}) => ({
  display: "grid",
  backgroundColor: theme.colors.white,
  fontSize: fontRemScale(0.875),
  [breakpoint("sm")]: {
    gridTemplateColumns: "5fr 1fr",
    minHeight: calcRem(144)
  }
}));
export const StyledCompetitionLink = styled(Link)(({theme}) => __assign(__assign({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.colors.white,
  textDecoration: "none",
  borderTopLeftRadius: theme.spacing.md,
  borderTopRightRadius: theme.spacing.md,
  "&:hover": {
    backgroundColor: theme.colors.palette.neutral30,
    outline: "none",
    svg: {
      opacity: "0.8"
    }
  }
}, insetFocusStyle(theme)), {
  [breakpoint("sm")]: {
    flexDirection: "row",
    padding: calcRem(theme.spacing.md),
    borderTopRightRadius: 0,
    borderBottomLeftRadius: theme.spacing.md
  }
}));
export const StyledCompetitionHeader = styled("div")(({theme}) => ({
  flex: "1 1 60%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: calcRem(theme.spacing.sm),
  background: theme.colors.gradientButton.background,
  color: theme.colors.gradientButton.text,
  fontSize: fontRemScale(1.5),
  fontWeight: theme.weights.bold,
  h4: {
    width: "80%",
    wordBreak: "break-word",
    overflow: "hidden"
  },
  svg: {
    opacity: "0.61",
    transition: theme.transitions.swift
  },
  [breakpoint("sm")]: {
    borderRadius: 3
  }
}));
export const StyledCardBody = styled("div")(({theme, showBadge}) => ({
  flex: "1 1 40%",
  display: "grid",
  gridTemplateRows: "1fr",
  gridTemplateColumns: showBadge ? "auto 2fr" : "1fr",
  columnGap: calcRem(theme.spacing.lg),
  padding: calcRem(theme.spacing.sm, theme.spacing.lg),
  color: theme.colors.palette.neutral90,
  textDecoration: "none",
  [breakpoint("sm")]: {
    gridTemplateColumns: showBadge ? "auto 2fr" : "1fr",
    padding: 0,
    marginLeft: calcRem(showBadge ? theme.spacing.md : theme.spacing.lg)
  },
  [breakpoint("lg")]: {
    gridTemplateColumns: showBadge ? "auto 2fr" : "1fr"
  }
}));
export const StyledInfo = styled(Link)(({theme}) => __assign({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: calcRem(theme.spacing.xxs),
  backgroundColor: theme.colors.white,
  color: theme.colors.palette.neutral90,
  textAlign: "center",
  textDecoration: "none",
  transition: theme.transitions.swift,
  borderTop: `1px solid ${theme.colors.palette.neutral50}`,
  borderBottomLeftRadius: theme.spacing.md,
  borderBottomRightRadius: theme.spacing.md,
  svg: {
    margin: calcRem(theme.spacing.xs)
  },
  [breakpoint("sm")]: {
    borderTop: "none",
    borderLeft: `1px solid ${theme.colors.palette.neutral50}`,
    padding: calcRem(theme.spacing.sm),
    flexDirection: "column",
    borderTopColor: "transparent",
    borderLeftColor: theme.colors.palette.neutral50,
    borderTopRightRadius: theme.spacing.md,
    borderBottomLeftRadius: 0
  },
  "&:hover": {
    backgroundColor: theme.colors.palette.neutral30,
    outline: "none"
  }
}, insetFocusStyle(theme)));
export const StyledCompetitionBadge = styled("div")(({theme}) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
  padding: calcRem(theme.spacing.xs),
  background: gradients.goldShimmerInverse,
  backgroundSize: "100% ",
  backgroundPosition: "center",
  borderRadius: 3,
  color: theme.colors.palette.gold900,
  fontSize: fontRemScale(1),
  maxWidth: calcRem(64),
  "& > *": {
    zIndex: 1
  },
  "&::before": {
    position: "absolute",
    top: 2,
    right: 2,
    bottom: 2,
    left: 2,
    background: gradients.goldShimmer,
    borderRadius: 3,
    content: '""'
  },
  [breakpoint("sm")]: {
    gridRow: "1 / span 2"
  },
  [breakpoint("md")]: {
    width: calcRem(64)
  }
}));

import React from 'react'
import {
    StyledSpinButton,
    StyledSpinButtonContainer,
    StyledSpinInput,
} from './SpinButton.styled'

export interface SpinButtonProps {
    id: string
    value?: number
    onChange?: (value: number) => void
    min?: number
    max?: number
    disabled?: boolean
}

/**
 * SpinButton
 * Follows the WAI-ARIA guidelines
 * https://www.w3.org/TR/wai-aria-practices-1.1/#spinbutton
 *
 */

export const SpinButton = React.forwardRef<HTMLInputElement, SpinButtonProps>(
    (props, ref) => {
        const {
            id,
            value = 0,
            onChange = () => undefined,
            min,
            max,
            disabled,
        } = props

        const [currentVal, setCurrentValue] = React.useState(value)

        function updateValue(value: number) {
            if (
                (typeof max === 'number' && value > max) ||
                (typeof min === 'number' && value < min)
            ) {
                return
            }

            setCurrentValue(value)
            onChange(value)
        }
        const handleFocus = (event: any) => event.target.select()

        return (
            <StyledSpinButtonContainer>
                <StyledSpinButton
                    aria-label="Decrease value by one"
                    aria-controls={id}
                    onClick={() => updateValue(currentVal - 1)}
                    tabIndex={-1}
                    variant="ghost"
                    disabled={disabled}
                    role="button"
                >
                    &minus;
                </StyledSpinButton>
                <StyledSpinInput
                    ref={ref}
                    name="marginInput"
                    id={id}
                    type="number"
                    value={currentVal}
                    onKeyDown={(event) => {
                        if (
                            event.key === 'Home' &&
                            typeof min !== 'undefined'
                        ) {
                            updateValue(min)
                        } else if (
                            event.key === 'End' &&
                            typeof max !== 'undefined'
                        ) {
                            updateValue(max)
                        }
                    }}
                    onChange={(evt) => {
                        evt.currentTarget.value = evt.currentTarget.value.replace(
                            /^0+/,
                            '',
                        )
                        updateValue(Number(evt.currentTarget.value))
                    }}
                    step="1"
                    disabled={disabled}
                    inputMode="numeric"
                    aria-label="Margin Input"
                    role="spinbutton"
                    aria-valuenow={currentVal}
                    aria-valuemin={min}
                    aria-valuemax={max}
                    onFocus={handleFocus}
                />
                <StyledSpinButton
                    aria-label="Increase value by one"
                    aria-controls={id}
                    onClick={() => updateValue(currentVal + 1)}
                    tabIndex={-1}
                    variant="ghost"
                    disabled={disabled}
                    role="button"
                >
                    +
                </StyledSpinButton>
            </StyledSpinButtonContainer>
        )
    },
)

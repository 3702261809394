var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {calcRem} from "@the-game/components/utils";
import styled from "@emotion/styled";
import {Card} from "../Card/Card";
import {Stack} from "../Stack";
import {focusStyle} from "@the-game/components/utils";
export const StyledModalWrapper = styled("div")(({theme}) => __assign({
  position: "fixed",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.modal
}, focusStyle(theme)));
const buttonSize = calcRem(22);
const fabDefaults = (theme, large) => __assign({
  backgroundColor: theme.colors.white,
  borderRadius: "50%",
  transition: "0.1s linear all",
  cursor: "pointer",
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: large ? calcRem(40) : buttonSize,
  minHeight: large ? calcRem(40) : buttonSize,
  width: large ? calcRem(40) : buttonSize,
  height: large ? calcRem(40) : buttonSize,
  padding: large ? calcRem(9) : calcRem(1),
  ":hover": {
    outline: "none",
    fill: theme.colors.palette.bayBlue600,
    backgroundColor: theme.colors.palette.neutral30
  }
}, focusStyle(theme));
export const StyledClosingButton = styled("button")(({theme}) => __assign(__assign({}, fabDefaults(theme)), {
  border: `2px solid ${theme.colors.primary}`
}));
export const StyledTooltipIcon = styled("button")(({theme, large}) => __assign(__assign({
  border: `none`
}, fabDefaults(theme, large)), theme.elevations.Elevation1));
export const StyledTooltipWrapper = styled("div")({
  display: "inline-block",
  padding: calcRem(0, 5)
});
export const StyledTooltipCard = styled(Card)(() => ({
  padding: calcRem(16),
  borderRadius: calcRem(8, 20, 8, 8),
  maxWidth: calcRem(330)
}));
export const StyledTooltipStack = styled(Stack)(({variant}) => ({
  "& > *:not(:last-child)": variant === "right" && {
    marginRight: 0,
    width: "100%"
  },
  minWidth: calcRem(225)
}));

var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {focusStyle} from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledButtonGroupItem = styled("button")(({theme}) => __assign(__assign({
  position: "relative",
  flexGrow: 1,
  margin: 0,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.primary,
  borderWidth: `2px 1px`,
  borderStyle: "solid",
  color: theme.colors.primary,
  cursor: "pointer",
  textDecoration: "none",
  transition: "background-color 0.1s linear",
  "&:first-child": {
    borderLeftWidth: 2,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  "&:last-child": {
    borderRightWidth: 2,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  }
}, focusStyle(theme)), {
  "@media (any-hover: hover)": {
    "&:hover": {
      backgroundColor: theme.colors.buttonGroup.hover
    }
  },
  "&[aria-checked=true]": {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    textDecoration: "underline"
  },
  "&[disabled]": {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.palette.neutral50,
    color: theme.colors.palette.neutral50,
    cursor: "not-allowed",
    "&[aria-checked=true]": {
      backgroundColor: theme.colors.palette.neutral50,
      color: theme.colors.palette.neutral90
    }
  }
}));

var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import styled from "@emotion/styled";
import {Card} from "../Card/Card";
import {calcRem} from "@the-game/components/utils";
import {focusStyle} from "@the-game/components/utils";
const gridSize = 16 * 3;
export const StyledNoticeCard = styled(Card)(({theme, variant}) => {
  const color = {
    error: theme.colors.palette.red500,
    warning: theme.colors.palette.orange500,
    success: theme.colors.palette.green500
  }[variant] || theme.colors.palette.orange500;
  return {
    pointerEvents: "auto",
    borderRadius: "0px",
    backgroundColor: theme.colors.white,
    padding: "0",
    display: "grid",
    gridTemplateColumns: `${calcRem(gridSize)} auto ${calcRem(gridSize)}`,
    gridGap: calcRem(theme.spacing.xs),
    alignItems: "center",
    justifyContent: "center",
    height: calcRem(gridSize),
    border: `solid 2px`,
    borderColor: color,
    "div:first-child": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      marginLeft: -2,
      backgroundColor: color
    },
    p: {
      overflow: "hidden",
      lineBreak: "initial",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  };
});
export const StyledCrossButton = styled("button")(({theme}) => __assign({
  border: "none",
  backgroundColor: theme.colors.white,
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer"
}, focusStyle(theme)));
export const StyledStickyDivTop = styled("div")(({theme}) => ({
  position: "fixed",
  pointerEvents: "none",
  zIndex: theme.zIndex.stickyFooter,
  top: theme.spacing.lg,
  padding: theme.spacing.md,
  left: 0,
  display: "flex",
  justifyContent: "center",
  width: "100%"
}));

import styled from "@emotion/styled";
import {calcRem} from "../utils";
export const StyledFlagList = styled("div")({
  display: "flex",
  maxWidth: "30rem",
  flexWrap: "wrap",
  justifyContent: "space-around",
  marginTop: "1.5rem",
  maxHeight: "18rem",
  overflow: "scroll",
  marginBottom: "1.5rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
});
export const StyledFlagContainer = styled("div")(({theme}) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  div: {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.colors.white,
    margin: 0
  }
}));
export const StyledFlagSelectorButton = styled("button")(({theme, active}) => ({
  borderWidth: 1,
  boxShadow: active ? `0 0 0.5rem ${theme.colors.primary}` : void 0,
  borderStyle: "solid",
  borderColor: active ? theme.colors.primary : theme.colors.palette.neutral50,
  margin: 0,
  borderRadius: "50%",
  marginRight: calcRem(theme.spacing.sm),
  marginLeft: calcRem(theme.spacing.sm),
  marginBottom: "2rem",
  cursor: "pointer",
  padding: 0
}));

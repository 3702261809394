var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {calcRem} from "@the-game/components/utils";
import styled from "@emotion/styled";
import {getVariantColor} from "@the-game/components/utils";
import {focusStyle} from "@the-game/components/utils";
function chevronDataURI(color) {
  return `url("data:image/svg+xml,%3Csvg viewBox='0 0 15 9' xmlns='http://www.w3.org/2000/svg' fill='${color.replace("#", "%23")}' %3E%3Ctitle%3E%7Btitle%7D%3C/title%3E%3Cpath d='M1.414 0L0 1.414l7.071 7.071 7.071-7.07L12.728 0 7.07 5.657z' /%3E%3C/svg%3E")`;
}
export const StyledDefaultOption = styled("select")({
  color: "green"
});
export const StyledSelect = styled("select")(({theme, fluid, variant, isDefault}) => __assign(__assign({
  display: "block",
  padding: calcRem(theme.spacing.sm, theme.spacing.xl, theme.spacing.sm, theme.spacing.sm),
  width: fluid ? "100%" : "auto",
  margin: 0,
  MozAppearance: "none",
  WebkitAppearance: "none",
  appearance: "none",
  backgroundColor: theme.colors.white,
  backgroundImage: chevronDataURI(theme.colors.palette.neutral90),
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 10px top 50%",
  backgroundSize: `${calcRem(theme.spacing.md)} auto`,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: getVariantColor(variant, theme),
  borderRadius: 0,
  color: isDefault ? theme.colors.palette.neutral70 : "inherit",
  fontFamily: theme.fontFamily,
  fontWeight: theme.weights.regular,
  "&:invalid": {
    color: theme.colors.palette.neutral70
  },
  "&::-ms-expand": {
    display: "none"
  },
  "&:focus": {
    borderColor: theme.colors.primary
  }
}, focusStyle(theme)), {
  "&:disabled": {
    backgroundColor: theme.colors.palette.neutral30,
    backgroundImage: chevronDataURI(theme.colors.palette.neutral70),
    borderColor: theme.colors.palette.neutral50,
    color: theme.colors.palette.neutral70
  },
  option: {
    fontWeight: theme.weights.regular
  }
}));

import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Cross({ title = 'Cross mark', ...props }: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 24 24"
            title={title}
            data-testid="cross-mark"
        >
            <path
                fill={props.fill ? props.fill : undefined}
                d="M6.2253,4.81108 C5.83477,4.42056 5.20161,4.42056 4.81108,4.81108 C4.42056,5.20161 4.42056,5.83477 4.81108,6.2253 L10.5858,12 L4.81114,17.7747 C4.42062,18.1652 4.42062,18.7984 4.81114,19.1889 C5.20167,19.5794 5.83483,19.5794 6.22535,19.1889 L12,13.4142 L17.7747,19.1889 C18.1652,19.5794 18.7984,19.5794 19.1889,19.1889 C19.5794,18.7984 19.5794,18.1652 19.1889,17.7747 L13.4142,12 L19.189,6.2253 C19.5795,5.83477 19.5795,5.20161 19.189,4.81108 C18.7985,4.42056 18.1653,4.42056 17.7748,4.81108 L12,10.5858 L6.2253,4.81108 Z"
            />
        </SvgIcon>
    )
}

export default Cross

import {breakpoint} from "@the-game/components/utils";
import styled from "@emotion/styled";
import {calcRem} from "@the-game/components/utils";
export const StyledBaseWrapper = styled("div")(({theme, grey = false}) => ({
  width: "100%",
  backgroundColor: grey ? theme.colors.palette.neutral30 : theme.colors.white
}));
export const StyledBase = styled("div", {
  shouldForwardProp: (prop) => prop !== "takeover"
})(({theme, takeover}) => ({
  display: "grid",
  maxWidth: takeover ? void 0 : theme.breakpoints.xl,
  margin: "0 auto",
  gridTemplateColumns: `repeat(4, minmax(0, 1fr))`,
  paddingTop: takeover ? 0 : calcRem(theme.spacing.md),
  paddingBottom: takeover ? 0 : calcRem(theme.spacing.xxxl),
  [breakpoint("sm")]: {
    gridTemplateColumns: `repeat(8, minmax(0, 1fr))`
  },
  [breakpoint("md")]: {
    gridTemplateColumns: `repeat(16, minmax(0, 1fr))`
  }
}));

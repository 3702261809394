import {
    Avatar,
    Button,
    ButtonGroup,
    ButtonGroupItem,
    Heading,
} from '@the-game/components'
import React, { ChangeEvent, useCallback, useState } from 'react'
import Modal from '../Modal'
import Typography from '../Typography'
import Info from '../Icons/Info'
import {
    aflTeamCodes,
    cricketTeamCodes,
    isSportName,
    SportName,
    Team,
    waflTeamCodes,
} from '@the-game/api-interfaces'
import {
    StyledFlagContainer,
    StyledFlagSelectorButton,
    StyledFlagList,
} from './TeamFlagSelector.styled'
import Stack from '../Stack'

export interface TeamFlagSelectorProps {
    teamFlag: Team | undefined
    handleValueChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void
}

export function TeamFlagSelector({
    teamFlag,
    handleValueChange,
}: TeamFlagSelectorProps) {
    const [modal, setModal] = useState(false)
    const [sport, setSport] = useState<SportName>('AFL')
    const [selectedFlag, setSelectedFlag] = useState<Team | undefined>(teamFlag)
    const close = useCallback(() => setModal(false), [])
    const codes = {
        AFL: aflTeamCodes,
        WAFL: waflTeamCodes,
        Cricket: cricketTeamCodes,
    }

    return (
        <>
            <Button
                size="md"
                fluid
                variant="ghost"
                onClick={() => setModal(true)}
            >
                Use featured avatar
            </Button>
            {modal && (
                <Modal active={false} close={close}>
                    <Stack justifyContent="center">
                        <Info
                            style={{ marginRight: '0.5rem' }}
                            size="sm"
                            themeColor="red500"
                        />
                        <Heading size="6" level="3">
                            Select an avatar
                        </Heading>
                    </Stack>
                    <Typography>
                        Pick a flag below to use for your avatar.
                    </Typography>
                    <ButtonGroup
                        defaultValue={sport}
                        onChange={(value) => {
                            if (isSportName(value)) {
                                setSport(value)
                            }
                        }}
                    >
                        <ButtonGroupItem id="afl" value={'AFL'}>
                            AFL
                        </ButtonGroupItem>

                        <ButtonGroupItem id="wafl" value={'WAFL'}>
                            WAFL
                        </ButtonGroupItem>
                        <ButtonGroupItem id="cricket" value={'Cricket'}>
                            Cricket
                        </ButtonGroupItem>
                    </ButtonGroup>
                    <StyledFlagList role="radiogroup">
                        {codes[sport].map((team) => (
                            <StyledFlagSelectorButton
                                key={team}
                                role="radio"
                                active={selectedFlag === team}
                                onClick={() => setSelectedFlag(team)}
                                type="button"
                            >
                                <StyledFlagContainer>
                                    <Avatar
                                        elevation={1}
                                        size="xl"
                                        spacing="md"
                                        teamFlag={team}
                                    />
                                </StyledFlagContainer>
                            </StyledFlagSelectorButton>
                        ))}
                    </StyledFlagList>
                    <Stack justifyContent="space-between">
                        <Button
                            size="md"
                            kind="neutral"
                            variant="ghost"
                            onClick={close}
                            style={{ flex: 1, marginRight: '0.5rem' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="md"
                            kind="primary"
                            disabled={!selectedFlag}
                            style={{ flex: 2 }}
                            onClick={() => {
                                handleValueChange({
                                    target: {
                                        name: 'teamFlag',
                                        value: selectedFlag as string,
                                    },
                                } as ChangeEvent<HTMLInputElement | HTMLSelectElement>)
                                close()
                            }}
                        >
                            Use selected avatar
                        </Button>
                    </Stack>
                </Modal>
            )}
        </>
    )
}

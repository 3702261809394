var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import styled from "@emotion/styled";
import {
  getElevationBySize,
  breakpoint,
  calcRem,
  focusStyle
} from "@the-game/components/utils";
export const StyledNewsCard = styled("a")(({theme, backgroundImage}) => __assign(__assign({
  background: `${backgroundImage ? `url('${backgroundImage}')` : theme.colors.primary} `,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  minHeight: calcRem(250),
  textDecoration: "none",
  color: theme.colors.white,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: calcRem(theme.spacing.md),
  cursor: "pointer",
  overflow: "hidden",
  transition: theme.transitions.swift,
  ":hover": __assign({}, getElevationBySize(3))
}, focusStyle(theme)), {
  borderRadius: 8,
  [breakpoint("sm")]: {
    borderRadius: 16
  }
}));
export const StyledTitle = styled("span")(({theme, isFirst}) => __assign(__assign({}, isFirst ? {
  fontSize: calcRem(23),
  lineHeight: 28 / 23,
  [breakpoint("sm")]: {
    fontSize: calcRem(31),
    lineHeight: 40 / 31
  },
  [breakpoint("md")]: {
    fontSize: calcRem(38),
    lineHeight: 40 / 38
  }
} : {
  fontSize: calcRem(14),
  lineHeight: 16 / 14,
  [breakpoint("sm")]: {
    fontSize: calcRem(20),
    lineHeight: 24 / 20
  },
  [breakpoint("md")]: {
    fontSize: calcRem(21),
    lineHeight: 24 / 21
  }
}), {
  fontWeight: theme.weights.bold,
  display: "block",
  zIndex: 1
}));
export const StyledDate = styled("span")(({theme}) => ({
  fontWeight: theme.weights.regular,
  zIndex: 1,
  display: "block",
  fontSize: calcRem(12),
  [breakpoint("sm")]: {
    fontSize: calcRem(14)
  }
}));
export const StyledOverlay = styled("div")(({theme, isFirst}) => ({
  transition: theme.transitions.swift,
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 0,
  opacity: 0.5,
  background: isFirst ? `linear-gradient(180deg, rgba(0,0,0,0.00) 38%, rgba(0,0,0,0.75) 71%, rgba(0,0,0,0.80) 100%)` : `linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.90) 100%)`
}));
